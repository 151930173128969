/* HTML: <div class="loader"></div> */
.loader {
  width: fit-content;
  font-weight: bold;
  font-family: monospace;
  font-size: 20px;
  color: green;
  clip-path: inset(0 3ch 0 0);
  animation: l4 1s steps(4) infinite;
}

.loader:before {
  content: "Loading..."
}

@keyframes l4 {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}

.btn-group-xs>.btn,
.btn-xs {
  padding: .35rem .5rem;
  font-size: .775rem;
  line-height: .5;
  border-radius: .2rem;
}